<template>
  <div :class="{'pbf': true, 'no-adv': brand_seed_data?.brand_package == 3}">

  <template v-if="brand_seed_data?.id">

    <BrandHeadBrand
      :id="brand_seed_data.id"
      :brand_package="brand_seed_data.brand_package"
      :nm="brand_seed_data.name"
      :info="brand_seed_data.description"
      :nmalt="brand_seed_data.name"
      :category="brand_seed_data.category"
      :category-name="$t('breeder_view_link_text')"
      :logo="brand_seed_data.logo_m"
      :link="brand_seed_data.section"
      :slogan="brand_seed_data.slogan"
      :background="brand_seed_data.background"
      :cover="brand_seed_data.cover"
      :cover_mobile="brand_seed_data.cover_mobile"
      :avatar="brand_seed_data.items_photo?.length ? brand_seed_data.items_photo[0].url_l : brand_seed_data.logo_l"
      :items-photo="brand_seed_data.items_photo"
      :pos="brand_seed_data.item_stat.rate"
      :rate="brand_seed_data.item_stat.avg_rate"
      :weight-plant="brand_seed_data.item_stat.avg_weight_plant"
      :cnt-followers="brand_seed_data.item_stat.cnt_followers"
      :cnt-reports="brand_seed_data.item_stat.cnt_reports"
      :cnt-harvests="brand_seed_data.item_stat.cnt_harvests"
      :seed-percent-auto="brand_seed_data.item_stat.seed_percent_auto"
      :seed-percent-photo="brand_seed_data.item_stat.seed_percent_photo"
      :from-year="brand_seed_data.item_stat.from"
      :country="brand_seed_data.country"
      :country-iso="brand_seed_data.country_iso"
      :isfollow="brand_seed_data?.addon?.follow"
      :type="type"
      :offical-user="brand_seed_data?.offical_user"
      :catalog-link="'/seedbank'"
      :harvests-link="'/seedbank/' + brand_seed_data.section + '/harvests'"
      :diaries-link="'/seedbank/' + brand_seed_data.section + '/diaries'"
      :bread="[
        {name: $t('breeder_view_link_text'), link: '/seedbank'},
        {name: brand_seed_data.name, link: brand_seed_data.section},
      ]"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >
    </BrandHeadBrand>

    <div class="pb grid_width">   
      
      <UiTabs 
        :tabs="getTabs" 
        :acttab="active_tab"
        />     
      <NuxtPage 
        :data="brand_seed_data"
        :id="brand_seed_data.id"
        />
    </div>



    <BrandContextMenu 
      :is-follow="brand_seed_data?.addon?.follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />


  </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 

  </div>


</template>

<script setup>


const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $ga, $follow, $popup} = useNuxtApp()
var id = ref(route.params['id']);
var active_tab = ref('info');
var type = ref('brandseed');


const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: brand_seed_data.value.link + '',          
    count: 0,
    icon: '',
  });

  if(brand_seed_data.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: brand_seed_data.value.link + '/diaries',
      count: brand_seed_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }

  if(brand_seed_data.value?.item_stat?.cnt_photos){
    items.push({
      id: 'gallery',
      name: 'Gallery',
      link: brand_seed_data.value.link + '/gallery',
      count: brand_seed_data.value?.item_stat?.cnt_photos,
      icon: '',
    });
  }

  if(brand_seed_data.value?.item_stat?.cnt_growers){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: brand_seed_data.value.link + '/growers',
      count: brand_seed_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(brand_seed_data.value?.item_stat?.cnt_harvests){
    items.push({
      id: 'Harvests',
      name: 'Harvests',
      link: brand_seed_data.value.link + '/harvests',
      count: brand_seed_data.value?.item_stat?.cnt_harvests,
      icon: '',
    });
  }

  if(brand_seed_data.value?.item_stat?.cnt_items){
    items.push({
      id: 'strains',
      name: 'Strains',
      link: brand_seed_data.value.link + '/strains',
      count: brand_seed_data.value?.item_stat?.cnt_items,
      icon: '',
    });
  }

  if(brand_seed_data.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: brand_seed_data.value.link + '/reviews',
      count: brand_seed_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  return items;
 
})
 
const follow = function(state) {  
  console.log(state)
  $follow.toggle('brand', brand_seed_data.value.id, state, {
    type: 'brand',
    id: brand_seed_data.value.id,
    avatar: brand_seed_data.value.avatar_small, 
    name: brand_seed_data.value.name, 
    link: brand_seed_data.value.link, 
  })
  .then((response) => {    
    brand_seed_data.value.addon.follow = state;
    if(brand_seed_data.value.item_stat)
      brand_seed_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 

const loadData = async function(load_id) {     
  const response = await $api.getBrandBySectionCategory(load_id, 'seed')
  return response;  
}


const { pending: is_loading,  data: brand_seed_data } = await useLazyAsyncData('brand_seed_data_' + id.value, async () => await loadData(id.value))
 


watch( () => brand_seed_data, () => {
  useHead(useNuxtApp().$head.getBrandSeed(brand_seed_data.value));
}, {immediate: true, deep: true});
 
watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getBrandSeed(brand_seed_data.value));
}, {immediate: true, deep: true});

  

onBeforeUnmount(() => {
  brand_seed_data.value = null;
})


 
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
